import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  process.env.NEXT_PUBLIC_SUPABASE_URL!,
  process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!
);

class accountManager {
  static userEmail = "";
  static userUuid = "";
  static userName = "";
  static userAvatar = "";

  static async SignIn(email: string, password: string) {
    try {
      const { data: user, error } = await supabase.auth.signInWithPassword({
        email: email,
        password: password,
      });
      if (error) {
        return {
          status: AccountResponseStatus.FAILURE,
          message: error.message,
        };
      } else {
        return {
          status: AccountResponseStatus.SUCCESS,
          message: "Sign in successful.",
          data: user,
        };
      }
    } catch (error) {
      return { status: AccountResponseStatus.FAILURE, message: error.message };
    }
  }

  static async SignOut() {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) {
        return {
          status: AccountResponseStatus.FAILURE,
          message: error.message,
        };
      } else {
        return {
          status: AccountResponseStatus.SUCCESS,
          message: "Sign out successful.",
        };
      }
    } catch (error) {
      return { status: AccountResponseStatus.FAILURE, message: error.message };
    }
  }

  static async SignUp(email: string, password: string) {
    try {
      const { data: user, error } = await supabase.auth.signUp({
        email: email,
        password: password,
      });
      if (error) {
        return {
          status: AccountResponseStatus.FAILURE,
          message: error.message,
        };
      } else {
        return {
          status: AccountResponseStatus.SUCCESS,
          message: "Sign up successful.",
          data: user,
        };
      }
    } catch (error) {
      return { status: AccountResponseStatus.FAILURE, message: error.message };
    }
  }

  static async ForgotPassword(email: string) {
    try {
      const { data: user, error } = await supabase.auth.resetPasswordForEmail(
        email,
        {
          redirectTo: "http://localhost:3000/account/user/resetPassword/",
        }
      );
      if (error) {
        return {
          status: AccountResponseStatus.FAILURE,
          message: error.message,
        };
      } else {
        return {
          status: AccountResponseStatus.SUCCESS,
          message:
            "If you registered using your email and password, you will receive a password reset email.",
          data: user,
        };
      }
    } catch (error) {
      return { status: AccountResponseStatus.FAILURE, message: error.message };
    }
  }

  static async ResetPassword(password) {
    try {
      const { data: resetPasswordData, error } = await supabase.auth.updateUser(
        { password: password }
      );
      if (error) {
        return {
          status: AccountResponseStatus.FAILURE,
          message: error.message,
        };
      } else {
        return {
          status: AccountResponseStatus.SUCCESS,
          message: "Password reset successful.",
          data: resetPasswordData,
        };
      }
    } catch (error) {
      return { status: AccountResponseStatus.FAILURE, message: error.message };
    }
  }

  static async UpdateEmail(email) {
    try {
      const { data: updateEmailData, error } = await supabase.auth.updateUser({
        email: email,
      });
      if (error) {
        return {
          status: AccountResponseStatus.FAILURE,
          message: error.message,
        };
      } else {
        return {
          status: AccountResponseStatus.SUCCESS,
          message:
            "Email update request successful. \nPlease confirm your new email.",
          data: updateEmailData,
        };
      }
    } catch (error) {
      return { status: AccountResponseStatus.FAILURE, message: error.message };
    }
  }

  static async GetSession() {
    try {
      const { data: user, error } = await supabase.auth.getSession();
      if (user.session) {
        let userData = user.session.user;
        this.userEmail = userData.email;
        this.userUuid = userData.id;
        return {
          status: AccountResponseStatus.SUCCESS,
          message: "User session found.",
          data: user,
        };
      } else {
        return {
          status: AccountResponseStatus.FAILURE,
          message: "Please Sign-in.",
        };
      }
    } catch (error) {
      return { status: AccountResponseStatus.FAILURE, message: error.message };
    }
  }

  static async GetUser() {
    try {
      const { data: userData, error } = await supabase
        .from("users")
        .select()
        .eq("user_id", this.userUuid);
      if (userData) {
        this.userName = userData[0].name;
        this.userAvatar = userData[0].avatar_url;
        return {
          status: AccountResponseStatus.SUCCESS,
          message: "Found user details.",
          data: userData,
        };
      } else {
        return {
          status: AccountResponseStatus.FAILURE,
          message: error.message,
        };
      }
    } catch (error) {
      return { status: AccountResponseStatus.FAILURE, message: error.message };
    }
  }

  static async GetPublicUrl(filePath) {
    try {
      const uploadedImageURL = supabase.storage
        .from("exports")
        .getPublicUrl(`${filePath}?t=${Date.now()}`);

      if (!uploadedImageURL) {
        return {
          status: AccountResponseStatus.FAILURE,
          message: `Failed to fetch ${filePath}`,
        };
      } else {
        return {
          status: AccountResponseStatus.SUCCESS,
          message: `Fetched ${filePath} url.`,
          data: uploadedImageURL.data.publicUrl,
        };
      }
    } catch (error) {
      return { status: AccountResponseStatus.FAILURE, message: error.message };
    }
  }

  static async UpdateUserName(newName) {
    try {
      const { data: userData, error } = await supabase
        .from("users")
        .update({ name: newName })
        .eq("user_id", this.userUuid);
      if (!userData) {
        return {
          status: AccountResponseStatus.SUCCESS,
          message: "Updated user details.",
        };
      } else {
        return {
          status: AccountResponseStatus.FAILURE,
          message: error.message,
          data: userData,
        };
      }
    } catch (error) {
      return { status: AccountResponseStatus.FAILURE, message: error.message };
    }
  }

  static async SetUserAvatar(file) {
    try {
      const { data, error } = await supabase.storage
        .from("users")
        .upload(`${this.userUuid}/user-avatar-${this.userUuid}`, file, {
          upsert: true,
          cacheControl: "no-cache",
        });

      if (data) {
        const uploadedImageName = data.path;
        const uploadedImageURL = supabase.storage
          .from("users")
          .getPublicUrl(uploadedImageName);

        const { error: injectAvatarUrlError } = await supabase
          .from("users")
          .update({
            avatar_url: uploadedImageURL.data.publicUrl,
          })
          .eq("user_id", this.userUuid);

        if (injectAvatarUrlError) {
          return {
            status: AccountResponseStatus.FAILURE,
            message: error.message,
          };
        } else {
          return {
            status: AccountResponseStatus.SUCCESS,
            message: "Updated Profile Image.",
          };
        }
      } else {
        return {
          status: AccountResponseStatus.FAILURE,
          message: error.message,
        };
      }
    } catch (error) {
      return { status: AccountResponseStatus.FAILURE, message: error.message };
    }
  }

  static async GetProjects() {
    try {
      const { data: projects, error } = await supabase
        .from("projects")
        .select("name, slug, user_id, users (name)");
      if (error) {
        return {
          status: AccountResponseStatus.FAILURE,
          message: error.message,
        };
      } else {
        return {
          status: AccountResponseStatus.SUCCESS,
          message: "Fetched projects successfully.",
          data: projects,
        };
      }
    } catch (error) {
      return { status: AccountResponseStatus.FAILURE, message: error.message };
    }
  }

  static async CreateProject({
    projectName,
    slug,
  }: {
    projectName: string;
    slug: string;
  }) {
    try {
      const { data: project, error } = await supabase
        .from("projects")
        .insert({
          name: projectName,
          slug,
          owner: this.userEmail,
          user_id: this.userUuid,
        })
        .select();
      if (error) {
        return {
          status: AccountResponseStatus.FAILURE,
          message: error.message,
        };
      } else {
        return {
          status: AccountResponseStatus.SUCCESS,
          message: "Created new project successfully.",
          data: project,
        };
      }
    } catch (error) {
      return { status: AccountResponseStatus.FAILURE, message: error.message };
    }
  }

  static async DeleteProject(projectName) {
    try {
      const { data: project, error } = await supabase
        .from("projects")
        .delete()
        .eq("owner", this.userEmail)
        .eq("slug", projectName);
      if (error) {
        return {
          status: AccountResponseStatus.FAILURE,
          message: error.message,
        };
      } else {
        return {
          status: AccountResponseStatus.SUCCESS,
          message: `Deleted ${projectName} successfully.`,
          data: project,
        };
      }
    } catch (error) {
      return { status: AccountResponseStatus.FAILURE, message: error.message };
    }
  }

  static async GetDeployments(projectSlug: string) {
    try {
      const { data: deployments, error } = await supabase
        .from("deployments")
        .select(
          "id, project_id, created_at, project:project_id(slug, current_deployment_id)"
        )
        .order("created_at", { ascending: false })
        .limit(100);

      const projectDeployments = deployments.filter((deployment) =>
        Array.isArray(deployment.project)
          ? false
          : deployment.project.slug === projectSlug
      );

      if (error) {
        return {
          status: AccountResponseStatus.FAILURE,
          message: error.message,
        };
      } else {
        return {
          status: AccountResponseStatus.SUCCESS,
          message: "Fetched deployments successfully.",
          data: projectDeployments,
        };
      }
    } catch (error) {
      return { status: AccountResponseStatus.FAILURE, message: error.message };
    }
  }

  static async UpdateJsonFile(filePath, file) {
    try {
      const { data, error } = await supabase.storage
        .from("exports")
        .update(`${filePath}?t=${Date.now()}`, file, {
          upsert: true,
          contentType: "application/json",
          cacheControl: "no-cache",
        });
      if (error) {
        return {
          status: AccountResponseStatus.FAILURE,
          message: error.message,
        };
      } else {
        return {
          status: AccountResponseStatus.SUCCESS,
          message: "Export updated successfully.",
          data: data,
        };
      }
    } catch (error) {
      return { status: AccountResponseStatus.FAILURE, message: error.message };
    }
  }

  static async CreateDeployment(projectSlug) {
    try {
      const projectsResult = await supabase
        .from("projects")
        .select("id")
        .eq("slug", projectSlug)
        .single();

      if (projectsResult.error) {
        return {
          status: AccountResponseStatus.FAILURE,
          message: projectsResult.error.message,
        };
      }

      const deploymentsInsertResult = await supabase
        .from("deployments")
        .insert({ project_id: projectsResult.data.id })
        .select("id, project_id")
        .single();

      if (deploymentsInsertResult.error) {
        return {
          status: AccountResponseStatus.FAILURE,
          message: deploymentsInsertResult.error.message,
        };
      }

      return {
        status: AccountResponseStatus.SUCCESS,
        message: "Deployment created successfully.",
        data: deploymentsInsertResult.data,
      };
    } catch (error) {
      return { status: AccountResponseStatus.FAILURE, message: error.message };
    }
  }

  static async PublishDeployment({
    deploymentId,
    projectId,
  }: {
    deploymentId: string;
    projectId: string;
  }) {
    const { error } = await supabase
      .from("projects")
      .update({ current_deployment_id: deploymentId })
      .eq("id", projectId);

    if (error) {
      return {
        status: AccountResponseStatus.FAILURE,
        message: error.message,
      };
    } else {
      return {
        status: AccountResponseStatus.SUCCESS,
        message: "Deployment published successfully.",
      };
    }
  }

  static async UploadProject({
    deploymentId,
    projectSlug,
    file,
  }: {
    deploymentId: string;
    projectSlug: string;
    file: File;
  }) {
    try {
      // Replace top level folder name with deploymentId and prepend project slug
      const filePathParts = file.webkitRelativePath.split("/");
      const filePath = `${projectSlug}/${deploymentId}/${filePathParts
        .slice(1)
        .join("/")}`;
      const fileData = await file.arrayBuffer();
      const { data, error } = await supabase.storage
        .from("exports")
        .upload(filePath, fileData, {
          upsert: true,
          contentType: file.type,
        });
      if (error) {
        return {
          status: AccountResponseStatus.FAILURE,
          message: error.message,
        };
      } else {
        return {
          status: AccountResponseStatus.SUCCESS,
          message: "Fetched project bucket successfully.",
          data: data,
        };
      }
    } catch (error) {
      return { status: AccountResponseStatus.FAILURE, message: error.message };
    }
  }

  static async ListFiles(folderName) {
    try {
      const { data: contents, error } = await supabase.storage
        .from("exports")
        .list(folderName);
      if (error) {
        return {
          status: AccountResponseStatus.FAILURE,
          message: error.message,
        };
      } else {
        return {
          status: AccountResponseStatus.SUCCESS,
          message: "Fetched project bucket successfully.",
          data: contents,
        };
      }
    } catch (error) {
      return { status: AccountResponseStatus.FAILURE, message: error.message };
    }
  }

  static async DeleteFiles(filePaths) {
    try {
      const { data, error } = await supabase.storage
        .from("exports")
        .remove(filePaths);
      if (error) {
        return {
          status: AccountResponseStatus.FAILURE,
          message: error.message,
        };
      } else {
        return {
          status: AccountResponseStatus.SUCCESS,
          message: "Successfully deleted.",
          data: data,
        };
      }
    } catch (error) {
      return { status: AccountResponseStatus.FAILURE, message: error.message };
    }
  }

  static get UserEmail() {
    return this.userEmail;
  }

  static get UserUuid() {
    return this.userUuid;
  }

  static get UserName() {
    return this.userName;
  }

  static get UserAvatar() {
    if (this.userAvatar == "") {
      this.userAvatar = "https://via.placeholder.com/128x128";
    }
    return this.userAvatar;
  }
}

export enum AccountResponseStatus {
  SUCCESS = "success",
  FAILURE = "failure",
}

export default accountManager;
