import { useEffect, useState } from "react";
import accountManager, {
  AccountResponseStatus,
} from "~/utils/accountService/accountManager";
import { useRouter } from "next/router";

export const signUpPage = `/account/user/signUp`;
export const signInPage = `/account/user/signIn`;
export const forgotPasswordPage = `/account/user/forgot-password`;
export const userDetailsPage = `/account/user/userDetails`;
export const userSettingsPage = `/account/user/userSettings`;
export const homePage = "/account";
export const treasuredLogoImg =
  "https://accounts.treasured.ca/resources/0tmtg/login/treasured/img/logo.png";

export default function Authentication() {
  const router = useRouter();

  useEffect(() => {
    GetAccountStatus();
  }, []);

  async function GetAccountStatus() {
    const response = await accountManager.GetSession();
    if (response.status == AccountResponseStatus.SUCCESS) {
      router.push(userDetailsPage);
    } else {
      router.push(signInPage);
    }
  }
  return <div>Loading...</div>;
}
